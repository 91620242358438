// redux/apis/endpoints/getCustomers.js
export const getCustomersEndpoint = (builder) => ({
    getCustomers: builder.query({
        query: ({ customerName, date, taxNumber, representative, page = 1, limit = 10 } = {}) => {
            console.log('Fetching customers with params:', { customerName, date, taxNumber, representative, page, limit });
            const params = new URLSearchParams();
            if (customerName) params.append('customerName', customerName);
            if (date) params.append('date', date);
            if (taxNumber) params.append('taxNumber', taxNumber);
            if (representative) params.append('representative', representative);
            params.append('page', page);
            params.append('limit', limit);
            return `api/customers/get-customers?${params.toString()}`;
        },
        providesTags: (result) =>
            result?.data
                ? [
                    ...result.data.map(({ _id }) => ({ type: 'Customer', id: _id })),
                    { type: 'Customer', id: 'LIST' },
                ]
                : [{ type: 'Customer', id: 'LIST' }],
    }),
});