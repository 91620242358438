// components/IncomingInvoiceForm.js
import React, { Fragment } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Col,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Alert,
} from "reactstrap";
import { useSelector } from "react-redux";
import {
  useGetCustomersQuery,
  useGetEmployeesQuery,
  useAddIncomingInvoiceMutation,
} from "../../../../redux/apis/apiSlice";
import { toast } from "react-toastify";

const IncomingInvoiceForm = () => {
  const { currentUser } = useSelector((state) => state.user);
  const {
    data: GetEmployeesData,
    error: errorGetEmployees,
    isLoading: isLoadingGetEmployees,
  } = useGetEmployeesQuery();
  const {
    data: GetCustomerData,
    error: errorCustomer,
    isLoading: isLoadingGetCustomer,
  } = useGetCustomersQuery();

  // تغيير اسم حالة التحميل لتجنب التعارض
  const [
    addIncomingInvoice,
    { isLoading: isMutationLoading, error: submitError, isSuccess },
  ] = useAddIncomingInvoiceMutation();

  const employees = GetEmployeesData?.data;
  const customers = GetCustomerData?.data;

  // تعريف مخطط التحقق باستخدام Yup
  const validationSchema = Yup.object().shape({
    customerName: Yup.string().trim(),
    invoiceAmount: Yup.number()
      .required("مبلغ الفاتورة مطلوب")
      .min(0, "مبلغ الفاتورة يجب أن يكون أكبر من الصفر"),
    invoiceDate: Yup.date()
      .max(
        new Date(),
        "تاريخ الفاتورة يجب أن يكون تاريخاً سابقاً أو مساوياً لتاريخ اليوم"
      )
      .required("تاريخ الفاتورة مطلوب"),
    employeeId: Yup.string().required("معرف الموظف مطلوب"),
    customer: Yup.string().required("معرف العميل مطلوب"),
  });

  // دالة للحصول على تاريخ اليوم بصيغة YYYY-MM-DD
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = `0${today.getMonth() + 1}`.slice(-2); // الشهور تبدأ من 0
    const day = `0${today.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };

  // القيم الابتدائية للنموذج مع تعيين تاريخ اليوم بشكل افتراضي
  const initialValues = {
    customerName: "" || "بدون اسم ",
    invoiceAmount: "",
    invoiceDate: getTodayDate(),
    employeeId: "",
    customer: "",
  };

  // دالة التعامل مع الإرسال
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const dataToSubmit = {
        ...values,
        userId: currentUser?.data?._id, // إضافة userId هنا
      };
      await addIncomingInvoice(dataToSubmit).unwrap();
      resetForm();
      toast.success("تم إضافة الفاتورة بنجاح!");
    } catch (err) {
      console.log(values);
      toast.error(
        err?.data?.message || "حدث خطأ أثناء إضافة الفاتورة. حاول مرة أخرى."
      );
      console.error("Failed to save the invoice: ", err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Fragment>
      <CardBody>
        {/* عرض رسالة نجاح إذا تم الإرسال بنجاح */}
        {isSuccess && <Alert color="success">تم إضافة الفاتورة بنجاح!</Alert>}
        {/* عرض رسالة خطأ إذا حدث خطأ أثناء الإرسال */}
        {submitError && (
          <Alert color="danger">
            حدث خطأ أثناء إضافة الفاتورة. حاول مرة أخرى.
          </Alert>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting: isFormikSubmitting, touched, errors }) => (
            <Form className="theme-form form-default">
              {/* اسم العميل */}
              <FormGroup row>
                <Label for="customerName" sm={3}>
                  اسم المورد
                </Label>
                <Col sm={9}>
                  <Field
                    name="customerName"
                    as={Input}
                    type="text"
                    placeholder="اسم المورد"
                    invalid={!!(touched.customerName && errors.customerName)}
                  />
                  <ErrorMessage
                    name="customerName"
                    component="div"
                    className="text-danger"
                  />
                </Col>
              </FormGroup>

              {/* مبلغ الفاتورة */}
              <FormGroup row>
                <Label for="invoiceAmount" sm={3}>
                  مبلغ الفاتورة
                </Label>
                <Col sm={9}>
                  <Field
                    name="invoiceAmount"
                    as={Input}
                    type="number"
                    placeholder="مبلغ الفاتورة"
                    invalid={!!(touched.invoiceAmount && errors.invoiceAmount)}
                  />
                  <ErrorMessage
                    name="invoiceAmount"
                    component="div"
                    className="text-danger"
                  />
                </Col>
              </FormGroup>

              {/* تاريخ الفاتورة */}
              <FormGroup row>
                <Label for="invoiceDate" sm={3}>
                  تاريخ الفاتورة
                </Label>
                <Col sm={9}>
                  <Field
                    name="invoiceDate"
                    as={Input}
                    type="date"
                    placeholder="تاريخ الفاتورة"
                    invalid={!!(touched.invoiceDate && errors.invoiceDate)}
                  />
                  <ErrorMessage
                    name="invoiceDate"
                    component="div"
                    className="text-danger"
                  />
                </Col>
              </FormGroup>

              {/* الموظف */}
              <FormGroup row>
                <Label for="employee" sm={3}>
                  الموظف
                </Label>
                <Col sm={9}>
                  <Field
                    name="employeeId"
                    as={Input}
                    type="select"
                    invalid={!!(touched.employeeId && errors.employeeId)}
                  >
                    <option value="">اختر الموظف</option>
                    {isLoadingGetEmployees ? (
                      <option disabled>جار التحميل...</option>
                    ) : errorGetEmployees ? (
                      <option disabled>خطأ في تحميل الموظفين</option>
                    ) : (
                      employees?.map((emp) => (
                        <option key={emp._id} value={emp._id}>
                          {emp.name}
                        </option>
                      ))
                    )}
                  </Field>
                  <ErrorMessage
                    name="employee"
                    component="div"
                    className="text-danger"
                  />
                </Col>
              </FormGroup>

              {/* العميل */}
              <FormGroup row>
                <Label for="customer" sm={3}>
                  العميل
                </Label>
                <Col sm={9}>
                  <Field
                    name="customer"
                    as={Input}
                    type="select"
                    invalid={!!(touched.customer && errors.customer)}
                  >
                    <option value="">اختر العميل</option>
                    {isLoadingGetCustomer ? (
                      <option disabled>جار التحميل...</option>
                    ) : errorCustomer ? (
                      <option disabled>خطأ في تحميل العملاء</option>
                    ) : (
                      customers?.map((cust) => (
                        <option key={cust._id} value={cust._id}>
                          {cust.customerName}
                        </option>
                      ))
                    )}
                  </Field>
                  <ErrorMessage
                    name="customer"
                    component="div"
                    className="text-danger"
                  />
                </Col>
              </FormGroup>

              {/* الحقل المخفي للمستخدم */}
              <Field type="hidden" name="userId" />

              {/* زر الإرسال */}
              <FormGroup row className="mb-0">
                <Label sm={3} className="col-form-label"></Label>
                <Col sm={9}>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={isFormikSubmitting || isMutationLoading}
                  >
                    {isFormikSubmitting || isMutationLoading ? (
                      <Spinner size="sm" />
                    ) : (
                      "اضافة"
                    )}
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Fragment>
  );
};

export default IncomingInvoiceForm;
