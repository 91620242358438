import Default from "../Pages/DashBoard/Default/Default";
import Ecommerce from "../Pages/DashBoard/Ecommerce/index";
import Project from "../Pages/DashBoard/Project/index";
import OrderHistory from "../Pages/Ecommerce/OrderHistory";
import PaymentDetails from "../Pages/Ecommerce/PaymentDetails";
import ProductList from "../Pages/Ecommerce/ProductList";
import ProductPage from "../Pages/Ecommerce/ProductPage";
import Products from "../Pages/Ecommerce/Products";
import FileManager from "../Pages/FileManager";
import KanbanBoard from "../Pages/KanbanBoard";
import CreateList from "../Pages/Project/Create List";
import ProjectList from "../Pages/Project/Project List";
import Charts from "../Pages/Widgets/Charts";
import General from "../Pages/Widgets/General";
import Invoice from "../Pages/Ecommerce/Invoice/index";
import ProductCart from "../Pages/Ecommerce/ProductCart";
import WishList from "../Pages/Ecommerce/Wishlist";
import CheckOut from "../Pages/Ecommerce/CheckOut";
import PricingMembership from "../Pages/Ecommerce/PricingMemberShip";
import MailInbox from "../Pages/Email/Mail Inbox";
import ReadMail from "../Pages/Email/Read Mail";
import Compose from "../Pages/Email/Compose";
import ChatApp from "../Pages/Chat/ChatApp";
import VideoChat from "../Pages/Chat/VideoChat";
import UsersProfile from "../Pages/Users/UsersProfile";
import UsersEdit from "../Pages/Users/UsersEdit";
import UsersCardss from "../Pages/Users/UsersCards";
import SocialApp from "../Pages/SocialApp";
import Typography from "../Pages/Ui-kit/Typography";
import Avatars from "../Pages/Ui-kit/Avtars";
import HelperClasses from "../Pages/Ui-kit/HelperClass";
import Grid from "../Pages/Ui-kit/Grid";
import TagAndPills from "../Pages/Ui-kit/TagAndPills";
import Progressbars from "../Pages/Ui-kit/Progress";
import Modals from "../Pages/Ui-kit/Modals";
import Alerts from "../Pages/Ui-kit/Alert";
import PopOvers from "../Pages/Ui-kit/Popover";
import Tooltips from "../Pages/Ui-kit/Tooltip";
import Spinners from "../Pages/Ui-kit/Spinners";
import Dropdowns from "../Pages/Ui-kit/Dropdown";
import TabBootstrap from "../Pages/Ui-kit/Tabs/BootstrapTab";
import TabLine from "../Pages/Ui-kit/Tabs/LineTab";
import Accordian from "../Pages/Ui-kit/Accordian";
import Shadow from "../Pages/Ui-kit/Shadows";
import List from "../Pages/Ui-kit/Lists";
import Calender from "../Pages/Calender/Index";
import SearchResult from "../Pages/Search Result";
import Draggable from "../Pages/Calender/Draggable Calender";
import FormValidation from "../Pages/Forms/Form Control/FormValidation";
import BaseInput from "../Pages/Forms/Form Control/BaseInput";
import InputGroups from "../Pages/Forms/Form Control/InputGroup";
import MegaOptions from "../Pages/Forms/Form Control/MegaOptions";
import RadioCheckbox from "../Pages/Forms/Form Control/RadioCheckbox";
import TodoFirebase from "../Pages/Todo Firebase";
import Bookmarks from "../Pages/BookMarks";
import Tasks from "../Pages/Tasks";
import Todo from "../Pages/Todo";
import Contact from "../Pages/Contact";
import ContactFirebase from "../Pages/Contact/contactFirebase";
import FormDatapicker from "../Pages/Forms/Form Widgets/FormDatepicker";
import FormTypeahead from "../Pages/Forms/Form Widgets/FormTypeahead";
import FormDefault from "../Pages/Forms/Form Layout/FormDefault";
import FormWizard from "../Pages/Forms/Form Layout/FormWizard";
import FormDateTime from "../Pages/Forms/Form Widgets/FormDateTime";
import FormSelect2 from "../Pages/Forms/Form Widgets/FormSelect2";
import FormSwitch from "../Pages/Forms/Form Widgets/FormSwitch";
import FormTouchspin from "../Pages/Forms/Form Widgets/FormTouchSpin";
import FormDateRangepicker from "../Pages/Forms/Form Widgets/FormDateRangepicker";
import FromClipboard from "../Pages/Forms/Form Widgets/FormClipboard";
import BlogDetail from "../Pages/Blog/BlogDetail";
import BlogPost from "../Pages/Blog/BlogPost";
import BlogSingle from "../Pages/Blog/BlogSingle";
import LearningList from "../Pages/Learning/LearningList";
import DetailedList from "../Pages/Learning/DetailedList";
import CardView from "../Pages/Job Search/CardView";
import ListView from "../Pages/Job Search/ListView";
import ApplyNow from "../Pages/Job Search/Apply";
import JobDetails from "../Pages/Job Search/JobDetail";
import CKEditor from "../Pages/Editor/CkEditor";
import MDEEditor from "../Pages/Editor/MDEEditor";
import ACEEditor from "../Pages/Editor/ACECodeEditor";
import KnowledgeBase from "../Pages/Knowledgebase/KnowledgeBase";
import KnowledgeCategory from "../Pages/Knowledgebase/KnowledgeCategory";
import KnowledgeDetails from "../Pages/Knowledgebase/KnowledgeDetails";
import SamplePage from "../Pages/Sample Page";
import MapJs from "../Pages/Maps/MapJs";
import SupportTickit from "../Pages/Support Tickit";
import FAQ from "../Pages/FAQ";
import GalleryGrid from "../Pages/Gallery/GalleryGrid";
import GalleryGridDesc from "../Pages/Gallery/GalleryGridDesc";
import MasonryGallery from "../Pages/Gallery/MasonryGallery";
import MasonryGalleryDesc from "../Pages/Gallery/MasonryGalleryDesc";
import HoverEffects from "../Pages/Gallery/HoverEffect";
import DataTable from "../Pages/Tables/DataTable";
import BasicTable from "../Pages/Tables/Reactstrap Table/BasicTable";
import SizingTable from "../Pages/Tables/Reactstrap Table/Sizingtable";
import StylingTable from "../Pages/Tables/Reactstrap Table/StylingTable";
import BorderTable from "../Pages/Tables/Reactstrap Table/BorderTable";
import DefaultStyle from "../Pages/Buttons/Defaultstyle";
import FlatStyle from "../Pages/Buttons/FlatStyle";
import RaisedStyle from "../Pages/Buttons/RaisedStyle";
import EdgeStyle from "../Pages/Buttons/EdgeStyle";
import AllButtonGroup from "../Pages/Buttons/AllButtonGroup";
import FlagIcon from "../Pages/Icons/FlagIcons";
import FontAwesome from "../Pages/Icons/FontAwesom";
import IcoIcons from "../Pages/Icons/IcoIcons";
import ThemifyIcon from "../Pages/Icons/ThemifyIcon";
import FeatherIcon from "../Pages/Icons/FeatherIcon";
import WhetherIcon from "../Pages/Icons/WhetherIcon";
import ApexChart from "../Pages/Charts/ApexChart";
import ChartistChart from "../Pages/Charts/ChartistChart";
import ChartJs from "../Pages/Charts/ChartJs";
import GoogleChart from "../Pages/Charts/GoogleChart";
import BasicCard from "../Pages/Bonus UI/BasicCard";
import CreativeCard from "../Pages/Bonus UI/CreativeCard";
import DraggingCard from "../Pages/Bonus UI/DraggingCard";
import TabCard from "../Pages/Bonus UI/TabCard";
import Carousel from "../Pages/Bonus UI/Carousels";
import BreadcrumbsClass from "../Pages/Bonus UI/Breadcrumb";
import ImageCroppers from "../Pages/Bonus UI/ImageCropper";
import Scrollables from "../Pages/Bonus UI/Scrollable";
import BootstrapNotify from "../Pages/Bonus UI/BootstrapNotify";
import RatingClass from "../Pages/Bonus UI/Rating";
import DropzoneClass from "../Pages/Bonus UI/Dropzone";
import Tours from "../Pages/Bonus UI/Tours";
import Ribbons from "../Pages/Bonus UI/Ribbon";
import RangeSlider from "../Pages/Bonus UI/RangeSilder";
import Pagination from "../Pages/Bonus UI/Pagination";
import ImageUpload from "../Pages/Bonus UI/ImageUpload";
import Sticky from "../Pages/Bonus UI/Sticky";
import TimeLines from "../Pages/Bonus UI/TimeLine";
import SweetAlerts from "../Pages/Bonus UI/SweetAleart";
import TreeViews from "../Pages/Bonus UI/TreeView";
import DragAndDrop from "../Pages/Bonus UI/DragAndDrop";
import Steps from "../Pages/Bonus UI/Steps";
import Employees from "../Pages/Employees/Employees";
import AddEmployee from "../Component/Employees/AddEmployee";
import EmployeeProfile from "../Component/Employees/EmployeeProfile";
import PaymentsPage from "../Pages/Payments";
import UsersProfileContain from "../Component/Employees/EmployeeProfile";
import Earnings from "../Component/Widgets/General/Earnings";
import Customers from "../Pages/Customers";
import IncomingInvoice from "../Pages/incomingInVoice/IncomingInvoice";
import Posts from "../Pages/Posts";
import AllPosts from "../Pages/Posts/AllPosts";
import AddGalleryCategory from "../Pages/galleryCategory";
import CardForWash from "../Pages/Posts/cardForWash";

export const routes = [
  {
    path: `${process.env.PUBLIC_URL}/employees`,
    roles: ["admin", "superAdmin"],
    Component: <Employees />,
  },
  {
    path: `${process.env.PUBLIC_URL}/add_employee`,
    roles: ["admin", "superAdmin"],
    Component: <AddEmployee />,
  },
  {
    path: `${process.env.PUBLIC_URL}/employees/:employeeId`,
    roles: ["admin", "superAdmin"],
    Component: <EmployeeProfile />,
    children: [
      // تعريف المسارات الفرعية هنا
      {
        path: "payments",
        roles: ["admin", "superAdmin"],
        Component: <PaymentsPage />,
      },
      {
        path: "earnings",
        roles: ["admin", "superAdmin"],
        Component: <Earnings />,
      },
      {
        path: "invoices",
        roles: ["admin", "superAdmin"],
        Component: <UsersProfileContain />,
      },
      {
        path: "incomingInvoice",
        roles: ["admin", "superAdmin"],
        Component: <IncomingInvoice />,
      },
    ],
  },
  {
    path: `${process.env.PUBLIC_URL}/incomingInvoice`,
    roles: ["admin", "superAdmin"],
    Component: <IncomingInvoice />,
  },
  {
    path: `${process.env.PUBLIC_URL}/customers`,
    roles: ["admin", "superAdmin"],
    Component: <Customers />,
  },
  {
    path: `${process.env.PUBLIC_URL}/posts`,
    roles: ["superAdmin"],
    Component: <Posts />,
  },
  {
    path: `${process.env.PUBLIC_URL}/cards-for-wash`,
    roles: ["superAdmin"],
    Component: <CardForWash />,
  },

  {
    path: `${process.env.PUBLIC_URL}/all-posts`,
    roles: ["superAdmin"],
    Component: <AllPosts />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gallery`,
    roles: ["superAdmin"],
    Component: <AddGalleryCategory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gallery/imageGallery`,
    roles: ["superAdmin" ,'writer'],
    Component: <GalleryGrid />,
  },
  {
    path: `${process.env.PUBLIC_URL}/payments/add-payment`,
    roles: ["admin", "superAdmin"],
    Component: <PaymentsPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/default/`,
    roles: ["admin", "superAdmin",'writer'],
    Component: <Default />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/ecommerce/`,
    roles: ["superAdmin"],
    Component: <Ecommerce />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/project/`,
    roles: ["superAdmin"],
    Component: <Project />,
  },

  {
    path: `${process.env.PUBLIC_URL}/widgets/general/`,
    roles: ["superAdmin"],
    Component: <General />,
  },
  {
    path: `${process.env.PUBLIC_URL}/widgets/chart/`,
    roles: ["superAdmin"],
    Component: <Charts />,
  },

  {
    path: `${process.env.PUBLIC_URL}/project/projectlist/`,
    roles: ["superAdmin"],
    Component: <ProjectList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/project/createlist/`,
    roles: ["superAdmin"],
    Component: <CreateList />,
  },

  {
    path: `${process.env.PUBLIC_URL}/filemanager`,
    roles: ["superAdmin"],
    Component: <FileManager />,
  },

  {
    path: `${process.env.PUBLIC_URL}/kanbanboard`,
    roles: ["superAdmin"],
    Component: <KanbanBoard />,
  },

  {
    path: `${process.env.PUBLIC_URL}/ecommerce/product`,
    roles: ["superAdmin"],
    Component: <Products />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/productpage`,
    roles: ["superAdmin"],
    Component: <ProductPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/productlist`,
    roles: ["superAdmin"],
    Component: <ProductList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/paymentdetails`,
    roles: ["superAdmin"],
    Component: <PaymentDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/orderhistory`,
    roles: ["superAdmin"],
    Component: <OrderHistory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/invoice`,
    roles: ["superAdmin"],
    Component: <Invoice />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/cart`,
    roles: ["superAdmin"],
    Component: <ProductCart />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/wishlist`,
    roles: ["superAdmin"],
    Component: <WishList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/checkout`,
    roles: ["superAdmin"],
    Component: <CheckOut />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/pricing`,
    roles: ["superAdmin"],
    Component: <PricingMembership />,
  },

  {
    path: `${process.env.PUBLIC_URL}/email/mailbox`,
    roles: ["superAdmin"],
    Component: <MailInbox />,
  },
  {
    path: `${process.env.PUBLIC_URL}/email/readmail`,
    roles: ["superAdmin"],
    Component: <ReadMail />,
  },
  {
    path: `${process.env.PUBLIC_URL}/email/compose`,
    roles: ["superAdmin"],
    Component: <Compose />,
  },

  {
    path: `${process.env.PUBLIC_URL}/chat/chatapp`,
    roles: ["superAdmin"],
    Component: <ChatApp />,
  },
  {
    path: `${process.env.PUBLIC_URL}/chat/videocall`,
    roles: ["superAdmin"],
    Component: <VideoChat />,
  },

  {
    path: `${process.env.PUBLIC_URL}/users/userprofile`,
    roles: ["superAdmin"],
    Component: <UsersProfile />,
  },
  {
    path: `${process.env.PUBLIC_URL}/users/useredit`,
    roles: ["superAdmin"],
    Component: <UsersEdit />,
  },
  {
    path: `${process.env.PUBLIC_URL}/users/usercard`,
    roles: ["superAdmin"],
    Component: <UsersCardss />,
  },

  {
    path: `${process.env.PUBLIC_URL}/bookmarks`,
    roles: ["superAdmin"],
    Component: <Bookmarks />,
  },

  {
    path: `${process.env.PUBLIC_URL}/social-app`,
    roles: ["superAdmin"],
    Component: <SocialApp />,
  },

  {
    path: `${process.env.PUBLIC_URL}/calandar/basic-calandar`,
    roles: ["superAdmin"],
    Component: <Calender />,
  },
  {
    path: `${process.env.PUBLIC_URL}/calandar/draggable-calandar`,
    roles: ["superAdmin"],
    Component: <Draggable />,
  },

  {
    path: `${process.env.PUBLIC_URL}/searchresult`,
    roles: ["superAdmin"],
    Component: <SearchResult />,
  },

  {
    path: `${process.env.PUBLIC_URL}/forms-control/formValidation`,
    roles: ["superAdmin"],
    Component: <FormValidation />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms-control/baseInput`,
    roles: ["superAdmin"],
    Component: <BaseInput />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms-control/inputGroup`,
    roles: ["superAdmin"],
    Component: <InputGroups />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms-control/megaOptions`,
    roles: ["superAdmin"],
    Component: <MegaOptions />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms-control/radioCheckbox`,
    roles: ["superAdmin"],
    Component: <RadioCheckbox />,
  },

  {
    path: `${process.env.PUBLIC_URL}/form-widget/datepicker`,
    roles: ["superAdmin"],
    Component: <FormDatapicker />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/typeahead`,
    roles: ["superAdmin"],
    Component: <FormTypeahead />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/datetimepicker`,
    roles: ["superAdmin"],
    Component: <FormDateTime />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/select2`,
    roles: ["superAdmin"],
    Component: <FormSelect2 />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/switch`,
    roles: ["superAdmin"],
    Component: <FormSwitch />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/touchspin`,
    roles: ["superAdmin"],
    Component: <FormTouchspin />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/rangepicker`,
    roles: ["superAdmin"],
    Component: <FormDateRangepicker />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/clipboard`,
    roles: ["superAdmin"],
    Component: <FromClipboard />,
  },

  {
    path: `${process.env.PUBLIC_URL}/form-layout/formDefault`,
    roles: ["superAdmin"],
    Component: <FormDefault />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-layout/formWizard`,
    roles: ["superAdmin"],
    Component: <FormWizard />,
  },

  {
    path: `${process.env.PUBLIC_URL}/todo`,
    roles: ["superAdmin"],
    Component: <Todo />,
  },

  {
    path: `${process.env.PUBLIC_URL}/todofirebase`,
    roles: ["superAdmin"],
    Component: <TodoFirebase />,
  },

  {
    path: `${process.env.PUBLIC_URL}/tasks`,
    roles: ["superAdmin"],
    Component: <Tasks />,
  },

  {
    path: `${process.env.PUBLIC_URL}/contacts`,
    roles: ["superAdmin"],
    Component: <Contact />,
  },
  {
    path: `${process.env.PUBLIC_URL}/contacts/firebase`,
    roles: ["superAdmin"],
    Component: <ContactFirebase />,
  },

  {
    path: `${process.env.PUBLIC_URL}/blog/blogdetail`,
    roles: ["superAdmin"],
    Component: <BlogDetail />,
  },
  {
    path: `${process.env.PUBLIC_URL}/blog/blogsingle`,
    roles: ["superAdmin"],
    Component: <BlogSingle />,
  },
  {
    path: `${process.env.PUBLIC_URL}/blog/blogpost`,
    roles: ["superAdmin"],
    Component: <BlogPost />,
  },

  {
    path: `${process.env.PUBLIC_URL}/ui-kits/typography/`,
    roles: ["superAdmin"],
    Component: <Typography />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/avatar/`,
    roles: ["superAdmin"],
    Component: <Avatars />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/helperclass/`,
    roles: ["superAdmin"],
    Component: <HelperClasses />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/grid/`,
    roles: ["superAdmin"],
    Component: <Grid />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/tagsandpills/`,
    roles: ["superAdmin"],
    Component: <TagAndPills />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/progress-bar/`,
    roles: ["superAdmin"],
    Component: <Progressbars />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/modal/`,
    roles: ["superAdmin"],
    Component: <Modals />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/alert/`,
    roles: ["superAdmin"],
    Component: <Alerts />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/popover/`,
    roles: ["superAdmin"],
    Component: <PopOvers />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/tooltips/`,
    roles: ["superAdmin"],
    Component: <Tooltips />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/spinner/`,
    roles: ["superAdmin"],
    Component: <Spinners />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/dropdown/`,
    roles: ["superAdmin"],
    Component: <Dropdowns />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/tab-bootstrap/`,
    roles: ["superAdmin"],
    Component: <TabBootstrap />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/tab-line/`,
    roles: ["superAdmin"],
    Component: <TabLine />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/accordion/`,
    roles: ["superAdmin"],
    Component: <Accordian />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/shadow/`,
    roles: ["superAdmin"],
    Component: <Shadow />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/list/`,
    roles: ["superAdmin"],
    Component: <List />,
  },

  {
    path: `${process.env.PUBLIC_URL}/learning/learning-list`,
    roles: ["superAdmin"],
    Component: <LearningList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/learning/learning-detail`,
    roles: ["superAdmin"],
    Component: <DetailedList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/jobSearch/cardView`,
    roles: ["superAdmin"],
    Component: <CardView />,
  },
  {
    path: `${process.env.PUBLIC_URL}/jobSearch/job-list`,
    roles: ["superAdmin"],
    Component: <ListView />,
  },
  {
    path: `${process.env.PUBLIC_URL}/jobSearch/job-detail`,
    roles: ["superAdmin"],
    Component: <JobDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/jobSearch/job-apply`,
    roles: ["superAdmin"],
    Component: <ApplyNow />,
  },

  {
    path: `${process.env.PUBLIC_URL}/editor/ckEditor`,
    roles: ["superAdmin"],
    Component: <CKEditor />,
  },
  {
    path: `${process.env.PUBLIC_URL}/editor/mdeEditor`,
    roles: ["superAdmin"],
    Component: <MDEEditor />,
  },
  {
    path: `${process.env.PUBLIC_URL}/editor/ace-code-editor`,
    roles: ["superAdmin"],
    Component: <ACEEditor />,
  },

  {
    path: `${process.env.PUBLIC_URL}/knowledgebase`,
    roles: ["superAdmin"],
    Component: <KnowledgeBase />,
  },
  {
    path: `${process.env.PUBLIC_URL}/knowledgebase/knowledge-category`,
    roles: ["superAdmin"],
    Component: <KnowledgeCategory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/knowledgebase/knowledge-details`,
    roles: ["superAdmin"],
    Component: <KnowledgeDetails />,
  },

  {
    path: `${process.env.PUBLIC_URL}/sample-page`,
    roles: ["superAdmin"],
    Component: <SamplePage />,
  },

  {
    path: `${process.env.PUBLIC_URL}/mapsjs`,
    roles: ["superAdmin"],
    Component: <MapJs />,
  },

  {
    path: `${process.env.PUBLIC_URL}/support-ticket`,
    roles: ["superAdmin"],
    Component: <SupportTickit />,
  },

  {
    path: `${process.env.PUBLIC_URL}/faq`,
    roles: ["superAdmin"],
    Component: <FAQ />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gallery/imageWithDesc`,
    roles: ["superAdmin"],
    Component: <GalleryGridDesc />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gallery/mesonryGallery`,
    roles: ["superAdmin"],
    Component: <MasonryGallery />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gallery/mesonryDesc`,
    roles: ["superAdmin"],
    Component: <MasonryGalleryDesc />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gallery/imageHover`,
    roles: ["superAdmin"],
    Component: <HoverEffects />,
  },

  {
    path: `${process.env.PUBLIC_URL}/tables/basictable`,
    roles: ["superAdmin"],
    Component: <BasicTable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tables/sizingtable`,
    roles: ["superAdmin"],
    Component: <SizingTable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tables/stylingtable`,
    roles: ["superAdmin"],
    Component: <StylingTable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tables/bordertable`,
    roles: ["superAdmin"],
    Component: <BorderTable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tables/datatable`,
    roles: ["superAdmin"],
    Component: <DataTable />,
  },

  {
    path: `${process.env.PUBLIC_URL}/buttons/default-style`,
    roles: ["superAdmin"],
    Component: <DefaultStyle />,
  },
  {
    path: `${process.env.PUBLIC_URL}/buttons/flat-style`,
    roles: ["superAdmin"],
    Component: <FlatStyle />,
  },
  {
    path: `${process.env.PUBLIC_URL}/buttons/edge-style`,
    roles: ["superAdmin"],
    Component: <EdgeStyle />,
  },
  {
    path: `${process.env.PUBLIC_URL}/buttons/raised-style`,
    roles: ["superAdmin"],
    Component: <RaisedStyle />,
  },
  {
    path: `${process.env.PUBLIC_URL}/buttons/button-group`,
    roles: ["superAdmin"],
    Component: <AllButtonGroup />,
  },

  {
    path: `${process.env.PUBLIC_URL}/icons/flagicon`,
    roles: ["superAdmin"],
    Component: <FlagIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/fontawesome`,
    roles: ["superAdmin"],
    Component: <FontAwesome />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/ico`,
    roles: ["superAdmin"],
    Component: <IcoIcons />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/thimify`,
    roles: ["superAdmin"],
    Component: <ThemifyIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/feather`,
    roles: ["superAdmin"],
    Component: <FeatherIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/wheater`,
    roles: ["superAdmin"],
    Component: <WhetherIcon />,
  },

  {
    path: `${process.env.PUBLIC_URL}/charts/apex`,
    roles: ["superAdmin"],
    Component: <ApexChart />,
  },
  {
    path: `${process.env.PUBLIC_URL}/charts/chartist`,
    roles: ["superAdmin"],
    Component: <ChartistChart />,
  },
  {
    path: `${process.env.PUBLIC_URL}/charts/chartjs`,
    roles: ["superAdmin"],
    Component: <ChartJs />,
  },
  {
    path: `${process.env.PUBLIC_URL}/charts/google`,
    roles: ["superAdmin"],
    Component: <GoogleChart />,
  },

  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/card/basicCards`,
    roles: ["superAdmin"],
    Component: <BasicCard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/card/creativeCards`,
    roles: ["superAdmin"],
    Component: <CreativeCard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/card/draggingCards`,
    roles: ["superAdmin"],
    Component: <DraggingCard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/card/tabCard`,
    roles: ["superAdmin"],
    Component: <TabCard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/carousel`,
    roles: ["superAdmin"],
    Component: <Carousel />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/breadcrumb`,
    roles: ["superAdmin"],
    Component: <BreadcrumbsClass />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/imageCropper`,
    roles: ["superAdmin"],
    Component: <ImageCroppers />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/scrollable`,
    roles: ["superAdmin"],
    Component: <Scrollables />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/bootstrap-notify`,
    roles: ["superAdmin"],
    Component: <BootstrapNotify />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/rating`,
    roles: ["superAdmin"],
    Component: <RatingClass />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/dropzone`,
    roles: ["superAdmin"],
    Component: <DropzoneClass />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/tourComponent`,
    roles: ["superAdmin"],
    Component: <Tours />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/ribbons`,
    roles: ["superAdmin"],
    Component: <Ribbons />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/rangeSlider`,
    roles: ["superAdmin"],
    Component: <RangeSlider />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/pagination`,
    roles: ["superAdmin"],
    Component: <Pagination />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/image-upload`,
    roles: ["superAdmin"],
    Component: <ImageUpload />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/stickyNotes`,
    roles: ["superAdmin"],
    Component: <Sticky />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/timelines/timeline1`,
    roles: ["superAdmin"],
    Component: <TimeLines />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/sweetAlert`,
    roles: ["superAdmin"],
    Component: <SweetAlerts />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/treeview`,
    roles: ["superAdmin"],
    Component: <TreeViews />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/dragNDropComp`,
    roles: ["superAdmin"],
    Component: <DragAndDrop />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/step`,
    roles: ["superAdmin"],
    Component: <Steps />,
  },
];
