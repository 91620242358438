// components/Earnings.js
import React, { Fragment, useEffect, useState } from "react";
import CountUp from "react-countup";
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap";
import {
  Database,
  DollarSign,
  MessageCircle,
  Percent,
  ShoppingBag,
  UserPlus,
} from "react-feather";
import {
  useGetFawateerByEmployeeQuery,
  useGetPaymentsByEmployeeQuery,
} from "../../../redux/apis/apiSlice";
import { useParams } from "react-router-dom";

const Earnings = () => {
  const { employeeId } = useParams();
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");

  // إنشاء مفتاح فريد لكل موظف
  const getLocalStorageKey = (id) => `profitPercentage_${id}`;

  // تهيئة الحالة مع تحميل القيمة من localStorage لكل موظف
  const [profitPercentage, setProfitPercentage] = useState(() => {
    if (!employeeId) return 0;
    const savedPercentage = localStorage.getItem(getLocalStorageKey(employeeId));
    return savedPercentage !== null ? parseFloat(savedPercentage) : 0;
  });

  // تحديث profitPercentage عند تغيير employeeId
  useEffect(() => {
    if (!employeeId) return;
    const savedPercentage = localStorage.getItem(getLocalStorageKey(employeeId));
    setProfitPercentage(savedPercentage !== null ? parseFloat(savedPercentage) : 0);
  }, [employeeId]);

  // حفظ نسبة الربح في localStorage عند تغييرها
  useEffect(() => {
    if (!employeeId) return;
    localStorage.setItem(getLocalStorageKey(employeeId), profitPercentage);
  }, [profitPercentage, employeeId]);

  const {
    data: FawateerByEmployee,
    error: FawateerByEmployeeError,
    isLoading: FawateerByEmployeeLoading,
    refetch: refetchFawateer,
  } = useGetFawateerByEmployeeQuery({
    employeeId,
    page: 1,
    limit: 10,
    dateFrom,
    dateTo,
    name,
    number,
  });

  const {
    data: paymentByEmployee,
    error: paymentByEmployeeError,
    isLoading: paymentByEmployeeLoading,
    refetch: refetchPayments,
  } = useGetPaymentsByEmployeeQuery({ employeeId, page: 1, limit: 10 });

  useEffect(() => {
    if (!employeeId) {
      console.error("Employee ID is missing");
      // يمكنك توجيه المستخدم إلى صفحة خطأ أو عرض رسالة مخصصة
    }
  }, [employeeId]);

  if (FawateerByEmployeeLoading || paymentByEmployeeLoading) {
    return <div>جاري التحميل...</div>;
  }

  if (FawateerByEmployeeError) {
    return (
        <div>
          خطأ في تحميل الفواتير:{" "}
          {FawateerByEmployeeError.data?.message || FawateerByEmployeeError.message}
        </div>
    );
  }

  if (paymentByEmployeeError) {
    return (
        <div>
          خطأ في تحميل المدفوعات:{" "}
          {paymentByEmployeeError.data?.message || paymentByEmployeeError.message}
        </div>
    );
  }

  // خريطة الألوان لتلوين الأيقونات بناءً على نوع البطاقة
  const colorMapping = {
    Q1: '#007bff', // أزرق
    Q2: '#28a745', // أخضر
    Q3: '#ffc107', // أصفر
    Q4: '#dc3545', // أحمر
    Profit: '#17a2b8', // معلومات
    Invoices: '#6c757d', // ثانوي
    Payments: '#007bff', // أزرق
    Count: '#28a745', // أخضر
    Difference: '#dc3545', // أحمر
    VAT: '#ffc107', // أصفر
  };

  const totalQ1Payments = paymentByEmployee?.data?.totalQ1 || 0;
  const totalQ2Payments = paymentByEmployee?.data?.totalQ2 || 0;
  const totalQ3Payments = paymentByEmployee?.data?.totalQ3 || 0;
  const totalQ4Payments = paymentByEmployee?.data?.totalQ4 || 0;

  const totalQ1Invoices = FawateerByEmployee?.totalQ1 || 0;
  const totalQ2Invoices = FawateerByEmployee?.totalQ2 || 0;
  const totalQ3Invoices = FawateerByEmployee?.totalQ3 || 0;
  const totalQ4Invoices = FawateerByEmployee?.totalQ4 || 0;

  const calculateDifference = (payments, invoices) => payments - invoices;

  const calculateTax = (difference) => {
    const tax = (difference / 1.15) * 0.15;
    return parseFloat(tax.toFixed(2));
  };

  // تعديل دالة حساب الربح لتستخدم إجمالي المدفوعات
  const calculateProfit = (payments) => (payments / 1.15) * (profitPercentage / 100);

  const calculatePercentage = (difference, invoices) =>
      invoices === 0 ? 0 : (difference / invoices) * 100;

  const quartersData = [
    {
      quarter: "Q1",
      color: "Q1", // اسم الربع لاستخدامه في SCSS
      payments: totalQ1Payments,
      invoices: totalQ1Invoices,
      difference: calculateDifference(totalQ1Payments, totalQ1Invoices),
      tax: calculateTax(calculateDifference(totalQ1Payments, totalQ1Invoices)),
      profit: calculateProfit(totalQ1Payments),
      percentage: calculatePercentage(calculateDifference(totalQ1Payments, totalQ1Invoices), totalQ1Invoices),
      number: 1, // الرقم بدلاً من الأيقونة
    },
    {
      quarter: "Q2",
      color: "Q2",
      payments: totalQ2Payments,
      invoices: totalQ2Invoices,
      difference: calculateDifference(totalQ2Payments, totalQ2Invoices),
      tax: calculateTax(calculateDifference(totalQ2Payments, totalQ2Invoices)),
      profit: calculateProfit(totalQ2Payments),
      percentage: calculatePercentage(calculateDifference(totalQ2Payments, totalQ2Invoices), totalQ2Invoices),
      number: 2, // الرقم بدلاً من الأيقونة
    },
    {
      quarter: "Q3",
      color: "Q3",
      payments: totalQ3Payments,
      invoices: totalQ3Invoices,
      difference: calculateDifference(totalQ3Payments, totalQ3Invoices),
      tax: calculateTax(calculateDifference(totalQ3Payments, totalQ3Invoices)),
      profit: calculateProfit(totalQ3Payments),
      percentage: calculatePercentage(calculateDifference(totalQ3Payments, totalQ3Invoices), totalQ3Invoices),
      number: 3, // الرقم بدلاً من الأيقونة
    },
    {
      quarter: "Q4",
      color: "Q4",
      payments: totalQ4Payments,
      invoices: totalQ4Invoices,
      difference: calculateDifference(totalQ4Payments, totalQ4Invoices),
      tax: calculateTax(calculateDifference(totalQ4Payments, totalQ4Invoices)),
      profit: calculateProfit(totalQ4Payments),
      percentage: calculatePercentage(calculateDifference(totalQ4Payments, totalQ4Invoices), totalQ4Invoices),
      number: 4, // الرقم بدلاً من الأيقونة
    },
  ];

  const totalPaymentsLastThreeMonths =
      paymentByEmployee?.data?.totalLastThreeMonths || 0;
  const totalFawateerLastThreeMonths =
      FawateerByEmployee?.totalAmountLastThreeMonths || 0;
  const difference =
      totalPaymentsLastThreeMonths - totalFawateerLastThreeMonths;

  const reducedPayments = totalPaymentsLastThreeMonths / 1.15;

  const vatTotalForDefrentPayment = (difference / 1.15) * 0.15;

  // تعديل حساب الربح الإجمالي بناءً على إجمالي المدفوعات
  const calculateTotalProfit = (totalPayments) => (totalPayments / 1.15) * (profitPercentage / 100);
  const profit = calculateTotalProfit(totalPaymentsLastThreeMonths);

  const NumbersUpData = [
    {
      type: "Profit",
      color: "Profit",
      icon: <Percent color={colorMapping['Profit']} size={50} aria-label="Profit Percent Icon" />,
      heading: "الربح بناءً على النسبة المحددة",
      amount: profit,
    },
    {
      type: "Invoices",
      color: "Invoices",
      icon: <Database color={colorMapping['Invoices']} size={50} aria-label="Invoices Database Icon" />,
      heading: "فواتير آخر ثلاث شهور",
      amount: totalFawateerLastThreeMonths,
    },
    {
      type: "Payments",
      color: "Payments",
      icon: <ShoppingBag color={colorMapping['Payments']} size={50} aria-label="Payments Shopping Bag Icon" />,
      heading: "دفعات آخر شهر",
      amount: paymentByEmployee?.data?.totalLastMonth || 0,
    },
    {
      type: "Payments",
      color: "Payments",
      icon: <MessageCircle color={colorMapping['Payments']} size={50} aria-label="Payments Message Circle Icon" />,
      heading: "دفعات آخر ثلاث شهور",
      amount: totalPaymentsLastThreeMonths,
    },
    {
      type: "Count",
      color: "Count",
      icon: <UserPlus color={colorMapping['Count']} size={50} aria-label="Count User Plus Icon" />,
      heading: "عدد الدفعات",
      amount: paymentByEmployee?.data?.totalCount || 0,
    },
    {
      type: "Difference",
      color: "Difference",
      icon: <DollarSign color={colorMapping['Difference']} size={50} aria-label="Difference Dollar Sign Icon" />,
      heading: "الفرق بين الدفعات والفواتير",
      amount: difference,
    },
    {
      type: "VAT",
      color: "VAT",
      icon: <Percent color={colorMapping['VAT']} size={50} aria-label="VAT Percent Icon" />,
      heading: "ضريبة المبلغ الخاضع للضريبة",
      amount: vatTotalForDefrentPayment,
    },
  ];

  const handleFilter = () => {
    refetchFawateer();
    refetchPayments();
  };

  return (
      <Fragment>
        {/* عناصر الفلترة */}
        <div className="date-filters mb-4">
          {/*<Row>
          <Col md="3" sm="6" className="mb-3">
            <Label for="dateFrom">من:</Label>
            <Input
                type="date"
                id="dateFrom"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
            />
          </Col>
          <Col md="3" sm="6" className="mb-3">
            <Label for="dateTo">إلى:</Label>
            <Input
                type="date"
                id="dateTo"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
            />
          </Col>
          <Col md="3" sm="6" className="mb-3">
            <Label for="name">اسم العميل:</Label>
            <Input
                type="text"
                id="name"
                placeholder="ادخل اسم العميل"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
          </Col>
          <Col md="3" sm="6" className="mb-3">
            <Label for="number">رقم الفاتورة:</Label>
            <Input
                type="text"
                id="number"
                placeholder="ادخل رقم الفاتورة"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
            />
          </Col>
        </Row>*/}
          {/* حقل نسبة الربح */}
          <Row className="mt-3">
            <Col md="3" sm="6">
              <Label for="profitPercentage">يرجى تحديد نسبة الربح:</Label>
              <Input
                  type="number"
                  id="profitPercentage"
                  placeholder="أدخل نسبة الربح (%)"
                  value={profitPercentage}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    setProfitPercentage(isNaN(value) ? 0 : value);
                  }}
                  step="0.1"
                  min="0"
              />
            </Col>
          </Row>
          {/*<Button color="primary" className="mt-3" onClick={handleFilter}>
          تطبيق الفلاتر
        </Button>*/}
        </div>

        {/* عرض بطاقات الأرباع في الأعلى */}
        <Row className="mb-4">
          {quartersData.map((qData) => (
              <Col sm="12" xl="3" lg="6" key={`quarter-${qData.quarter}`} className="mb-4">
                <Card className={`quarter-card ${qData.quarter}`}>
                  <CardBody className="quarter-card-body">
                    {/* الرقم بدلاً من الأيقونة */}
                    <div className="quarter-icon">
                      {qData.number}
                    </div>
                    {/* معلومات الربع */}
                    <div className="quarter-info">
                      <div className="quarter-title">{`إجمالي الربع ${qData.quarter}`}</div>
                      <div className="quarter-value">
                        <CountUp
                            end={qData.payments}
                            separator=","
                            decimals={2}
                            decimal="."
                        />
                      </div>
                      {/* معلومات إضافية */}
                      <div className="quarter-subinfo">
                    <span>
                      مجموع الفواتير: <strong>{qData.invoices.toLocaleString()}</strong>
                    </span>
                        <span>
                      الفرق: <strong>{qData.difference.toLocaleString()}</strong>
                    </span>
                        <span>
                      ضريبة الفرق 15%: <strong>{qData.tax.toLocaleString()}</strong>
                    </span>
                        <span>
                      الربح بناءً على النسبة المحددة: <strong>{qData.profit.toLocaleString()}</strong>
                    </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
          ))}
        </Row>

        {/* عرض البيانات الأخرى */}
        <Row>
          {NumbersUpData.map((data, i) => (
              <Col sm="6" xl="3" lg="6" key={`data-${i}`} className="mb-4">
                <Card className={`numbers-up-card ${data.type}`}>
                  <CardBody className="numbers-up-card-body">
                    {/* الأيقونة الخلفية */}
                    <div className="numbers-up-icon">
                      {data.icon}
                    </div>
                    {/* معلومات البيانات */}
                    <div className="numbers-up-info">
                      <div className="numbers-up-title">{data.heading}</div>
                      <div className="numbers-up-value">
                        <CountUp
                            end={data.amount}
                            separator=","
                            decimals={2}
                            decimal="."
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
          ))}
        </Row>
      </Fragment>
  );
};

export default Earnings;