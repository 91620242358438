// src/redux/apis/paymentsEndpoints.js
export const getPaymentsByEmployeeEndpoint = (builder) => ({
    getPaymentsByEmployee: builder.query({
        query: ({ employeeId, page = 1, limit = 10, customerName, startDate, endDate, amount }) => ({
            url: `api/payments/${employeeId}/payments`,
            params: { page, limit, customerName, startDate, endDate, amount },
        }),
        providesTags: (result, error, { employeeId }) => [
            { type: 'Payments', id: 'LIST' },
            { type: 'Payments', id: employeeId },
        ],
    }),
});